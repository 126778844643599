import { DrugField } from '@api/enums';
import type { KeyOfType } from '@helpers/types';
import type { DrugText, TitleTextDiff } from '../../../API';
import createCountryFieldUtil from './countryFieldUtil';

/**
 * Controls the order of options in the add a section list.
 */
export const FIELD_CHOICES = [
    DrugField.label,
    DrugField.indicationsAndUsage,
    DrugField.mechanismOfAction,
    DrugField.descriptionSection,
    DrugField.representedOrganization,
    DrugField.activeIngredient,
    DrugField.inactiveIngredient,
    DrugField.brandName,
    DrugField.dosageAndAdministration,
    DrugField.boxedWarning,
    DrugField.warningsAndPrecautions,
    DrugField.adverseReactions,
    DrugField.contraindications,
    DrugField.drugInteractions,
    DrugField.useInSpecificPopulations,
    DrugField.clinicalStudiesSection,
    DrugField.clinicalPharmacology,
    DrugField.patientCounselingInformation
];

/**
 * Can optionally override the text. Defaults to titleCase.
 */
export const NAME_OVERRIDES = {
    [DrugField.label]: 'Search in all sections',
    [DrugField.dosageAndAdministration]: 'Dosage / Administration',
    [DrugField.warningsAndPrecautions]: 'Warnings / Precautions',
    [DrugField.representedOrganization]: 'Company / Packager',
    [DrugField.descriptionSection]: 'Description',
    [DrugField.clinicalStudiesSection]: 'Clinical Studies'
};

export type SplSectionProperty = KeyOfType<DrugText, TitleTextDiff[]>;

/**
 * Controls the order and labels of the history chart.
 */
const HISTORY_SECTIONS: [string, SplSectionProperty][] = [
    ['Boxed Warning', 'boxedWarning'],
    ['Indications and Usage', 'indicationsAndUsage'],
    ['Dosage / Administration', 'dosageAndAdministration'],
    ['Dosage Forms / Strengths', 'dosageFormsAndStrengths'],
    ['Contraindications', 'contraindications'],
    ['Warnings / Precautions', 'warningsAndPrecautions'],
    ['Adverse Reactions', 'adverseReactions'],
    ['Drug Interactions', 'drugInteractions'],
    ['Specific Populations', 'useInSpecificPopulations'],
    ['Drug Abuse / Dependence', 'drugAbuseAndDependence'],
    ['Overdosage', 'overdosageSection'],
    ['Description', 'descriptionSection'],
    ['Clinical Pharmacology', 'clinicalPharmacology'],
    ['Nonclinical Toxicology', 'nonclinicalToxicology'],
    ['Clinical Studies', 'clinicalStudiesSection'],
    ['References', 'referencesSection'],
    ['How Supplied/Storage and Handling', 'howSuppliedStorageAndHandling'],
    ['Patient Counseling Information', 'patientCounselingInformation'],
]

export const spl = createCountryFieldUtil<DrugField>(
    DrugField,
    FIELD_CHOICES,
    NAME_OVERRIDES,
    HISTORY_SECTIONS
);

// TODO: refactor out usages, and go through the global instead.
export const {
    formatField,
    formatFieldForSubtitle
} = spl;

interface ScrollerBehavior {
    findElement: (doc: ParentNode) => Element | null;
    // TODO: may want to include top/bottom.
    findPosition: (doc: ParentNode) => Element | null;
}

interface JumpSectionInfo {
    key: string; // use the code or any other unique key.
    label: string;
    inset: boolean;
    behavior: ScrollerBehavior;
}

function subSection(
    code: string,
    title: string,
    titleRegex: RegExp,
    sectionNumber: number,
    subsectionNumber: number,
): JumpSectionInfo {
    return {
        key: code,
        label: `${sectionNumber}.${subsectionNumber} ${title}`,
        inset: true,
        behavior: {
            findElement: (doc) => {
                const el = doc.querySelector(`[data-sectioncode="${code}"]`);
                if (el) return el;
                const titles = doc.querySelectorAll('.Section h2');
                const match = [...titles].find(el => titleRegex.test(el.textContent));
                if (match) return match;
            },
            findPosition: (doc) => {
                return null; // TODO
            }
        }
    }
}

function topSection(
    code: string,
    title: string,
    titleRegex: RegExp,
    sectionNumber: number,
): JumpSectionInfo {
    return {
        key: code,
        label: `${sectionNumber} ${title}`,
        inset: false,
        behavior: {
            findElement: (doc) => {
                const el = doc.querySelector(`[data-sectioncode="${code}"]`);
                if (el) return el;
                const titles = doc.querySelectorAll('.Section h1');
                const match = [...titles].find(el => titleRegex.test(el.textContent));
                if (match) return match;
            },
            findPosition: (doc) => {
                return null; // TODO
            }
        }
    }
}

function unnumberedSection(
    code: string,
    title: string,
    findElement?: (doc: ParentNode) => Element | null,
): JumpSectionInfo {
    return {
        key: code,
        label: title,
        inset: false,
        behavior: {
            findElement: (doc) => {
                return doc.querySelector(`[data-sectioncode="${code}"]`)
                    ?? findElement?.(doc);
            },
            findPosition: (doc) => {
                return null; // TODO
            }
        }
    }
}

export const JUMP_TO_NEW = [
    unnumberedSection(
        '34066-1',
        'Boxed Warning',
        // Note: the code is not actually used in the HTML.
        // Note: need to make sure that it matches the div in the body, not the summary
        (doc) => doc.querySelector('.Contents .Warning')
    ),
    topSection(
        '34067-9',
        'Indications and Usage',
        /indications.*usage/ig,
        1
    ),
    topSection(
        '34068-7',
        'Dosage / Administration',
        /dosage.*administration/ig,
        2
    ),
    topSection(
        '43678-2',
        'Dosage Forms / Strengths',
        /dosage.*forms/ig,
        3
    ),
    topSection(
        '34070-3',
        'Contraindications',
        /contraindications/ig,
        4
    ),
    topSection(
        '43685-7',
        'Warnings and Precautions',
        /warnings/ig,
        5
    ),
    // old format has 2 top level sections + subsections
    //    ['34071-1', 'Warnings', false],
    //        ['42232-9', 'PRECAUTIONS', false],
    topSection(
        '34084-4',
        'Adverse Reactions',
        /adverse\w+reactions/ig,
        6
    ),
    subSection(
        '90374-0',
        'Clinical Trials Experience',
        // Note: some use "clinical studies" instead of "clinical trials"
        /Clinical.*Experience/ig,
        6,
        1
    ),
    subSection(
        '90375-7',
        'Postmarketing Experience',
        // some have a different title, like "Experience with Immediate-Release Clonidine" or "Postmarketing Reports"
        /Postmarketing/ig,
        6,
        2
    ),
    topSection(
        '34073-7',
        'Drug Interactions',
        /Drug\w+Interaction/ig,
        7
    ),
    topSection(
        '43684-0',
        'Use In Specific Populations',
        /Specific\w+Populations/ig,
        8
    ),
    subSection(
        '42228-7',
        'Pregnancy',
        /Pregnancy/ig,
        8,
        1
    ),
    subSection(
        '77290-5',
        'Lactation',
        /Lactation/ig,
        8,
        2
    ),// [[for PLLR format]]
    subSection(
        '34079-4',
        'Labor and Delivery',
        /Labor.*Delivery/ig,
        8,
        2
    ), //[[for non-PLLR format]]
    subSection(
        '77291-3',
        'Females and Males of Reproductive Potential',
        /Reproductive\w+Potential/ig,
        8,
        3
    ), //[[for PLLR format]]
    subSection(
        '34080-2',
        'Nursing Mothers',
        /Nursing/ig,
        8,
        3
    ), //[[for non-PLLR format]]
    subSection(
        '34081-0',
        'Pediatric Use',
        /Pediatric/ig,
        8,
        4
    ),
    subSection(
        '34082-8',
        'Geriatric Use',
        /Geriatric/ig,
        8,
        5
    ),
    //['88828-9', 'Renal Impairment', true],
    //['88829-7', 'Hepatic Impairment', true],
    topSection(
        '42227-9',
        'Drug Abuse / Dependence',
        /Drug\w+Abuse/ig,
        9
    ),
    subSection(
        '34085-1',
        'Controlled Substance',
        /Controlled\w+Substance/ig,
        9,
        1
    ),
    subSection(
        '34086-9',
        'Abuse',
        /Abuse/ig,
        9,
        2
    ),
    subSection(
        '34087-7',
        'Dependence',
        /Dependence/ig,
        9,
        3
    ),
    topSection(
        '34088-5',
        'Overdosage',
        /Overdosage/ig,
        10
    ),
    topSection(
        '34089-3',
        'Description',
        /Description/ig,
        11
    ),
    topSection(
        '34090-1',
        'Clinical Pharmacology',
        /Clinical Pharmacology/ig,
        12
    ),
    subSection(
        '43679-0',
        'Mechanism of Action',
        /Mechanism of Action/ig,
        12,
        1
    ),
    subSection(
        '43681-6',
        'Pharmacodynamics',
        /Pharmacodynamics/ig,
        12,
        2
    ),
    subSection(
        '43682-4',
        'Pharmacokinetics',
        /Pharmacokinetics/ig,
        12,
        3
    ),
    subSection(
        '49489-8',
        'Microbiology',
        /Microbiology/ig,
        12,
        4
    ),
    subSection(
        '66106-6',
        'Pharmacogenomics',
        /Pharmacogenomics/ig,
        12,
        5
    ),
    subSection(
        '88830-5',
        'Immunogenicity',
        /Immunogenicity/ig,
        12,
        6
    ),
    topSection(
        '43680-8',
        'Nonclinical Toxicology',
        /Nonclinical Toxicology/ig,
        13
    ),
    subSection(
        '34083-6',
        'Carcinogenesis, Mutagenesis, Impairment of Fertility',
        /Carcinogenesis/ig,
        13,
        1
    ),
    subSection(
        '34091-9',
        'Animal Toxicology and/or Pharmacology',
        /Animal Toxicology/ig,
        13,
        2
    ),
    topSection(
        '34092-7',
        'Clinical Studies',
        /Clinical Studies/ig,
        14
    ),
    topSection(
        '34093-5',
        'References',
        /References/ig,
        15
    ),
    topSection(
        '34069-5',
        'How Supplied/Storage and Handling',
        /How Supplied/ig,
        16
    ),
    topSection(
        '88436-1',
        'Patient Counseling Information',
        /Patient Counseling Information/ig,
        17
    ),
    // Patient labeling
    unnumberedSection(
        '59845-8',
        'Instructions for Use'
    ),
    unnumberedSection(
        '42231-1',
        'Medication Guide',  //SPL MEDGUIDE
        // <a name="splSectionMedguide">
    ),
    unnumberedSection(
        '42230-3',
        'Patient Package Insert'
    ),
    unnumberedSection(
        '51945-4',
        'Principal Display Panel'// PACKAGE LABEL.
    ),
    unnumberedSection(
        'tables',
        'Product Information Table',
        (doc) => doc.querySelector('.DataElementsTables')
    )
]

// Note: might want to save the index.
export const getJumpInfo = (key: string): JumpSectionInfo => {
    return JUMP_TO_NEW.find(o => o.key === key);
}
