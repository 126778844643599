import { default as api } from '@api/emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postBasicFivetenkList: build.query<
      PostBasicFivetenkListApiResponse,
      PostBasicFivetenkListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/fivetenk/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicDenovoList: build.query<
      PostBasicDenovoListApiResponse,
      PostBasicDenovoListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/de-novo/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicPmaList: build.query<
      PostBasicPmaListApiResponse,
      PostBasicPmaListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/pma/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicPmaSupplementList: build.query<
      PostBasicPmaSupplementListApiResponse,
      PostBasicPmaSupplementListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/pma-supplement/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicHdeList: build.query<
      PostBasicHdeListApiResponse,
      PostBasicHdeListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/hde/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicHdeSupplementList: build.query<
      PostBasicHdeSupplementListApiResponse,
      PostBasicHdeSupplementListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/hde-supplement/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicFivetenkDetails: build.query<
      GetBasicFivetenkDetailsApiResponse,
      GetBasicFivetenkDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/fivetenk/${queryArg}`,
      }),
    }),
    getBasicDenovoDetails: build.query<
      GetBasicDenovoDetailsApiResponse,
      GetBasicDenovoDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/de-novo/${queryArg}`,
      }),
    }),
    getBasicPmaDetails: build.query<
      GetBasicPmaDetailsApiResponse,
      GetBasicPmaDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/device-data/v1/basic/pma/${queryArg}` }),
    }),
    getBasicPmaSupplementDetails: build.query<
      GetBasicPmaSupplementDetailsApiResponse,
      GetBasicPmaSupplementDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/pma/${queryArg.pmaNumber}/supplement/${queryArg.pmaSupplementNumber}`,
      }),
    }),
    getBasicHdeDetails: build.query<
      GetBasicHdeDetailsApiResponse,
      GetBasicHdeDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/device-data/v1/basic/hde/${queryArg}` }),
    }),
    getBasicHdeSupplementDetails: build.query<
      GetBasicHdeSupplementDetailsApiResponse,
      GetBasicHdeSupplementDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/hde/${queryArg.hdeNumber}/supplement/${queryArg.hdeSupplementNumber}`,
      }),
    }),
    postBasicClinicalTrialList: build.query<
      PostBasicClinicalTrialListApiResponse,
      PostBasicClinicalTrialListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/clinical-trial/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicClinicalTrialDetails: build.query<
      GetBasicClinicalTrialDetailsApiResponse,
      GetBasicClinicalTrialDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/clinical-trial/${queryArg}`,
      }),
    }),
    getBasicProductCodeDetails: build.query<
      GetBasicProductCodeDetailsApiResponse,
      GetBasicProductCodeDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/product-code/${queryArg}`,
      }),
    }),
    postBasicProductCodeList: build.query<
      PostBasicProductCodeListApiResponse,
      PostBasicProductCodeListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/product-code/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicEcosystem: build.query<
      GetBasicEcosystemApiResponse,
      GetBasicEcosystemApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/ecosystem`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicGuidanceList: build.query<
      PostBasicGuidanceListApiResponse,
      PostBasicGuidanceListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/guidance/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicApplicationTypeMeta: build.query<
      GetBasicApplicationTypeMetaApiResponse,
      GetBasicApplicationTypeMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/meta/application/type`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicRecallEventList: build.query<
      GetBasicRecallEventListApiResponse,
      GetBasicRecallEventListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/recall-event/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postBasicRegulationList: build.query<
      PostBasicRegulationListApiResponse,
      PostBasicRegulationListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/regulation/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getBasicRegulationDetails: build.query<
      GetBasicRegulationDetailsApiResponse,
      GetBasicRegulationDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/regulation/${queryArg}`,
      }),
    }),
    getBasicAllList: build.query<
      GetBasicAllListApiResponse,
      GetBasicAllListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/basic/summary/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getDashboard: build.query<GetDashboardApiResponse, GetDashboardApiArg>({
      query: () => ({ url: `/device-data/v1/dashboard` }),
    }),
    getEcosystem: build.query<GetEcosystemApiResponse, GetEcosystemApiArg>({
      query: (queryArg) => ({
        url: `/device-data/v1/ecosystem`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecFivetenkList: build.query<
      PostExecFivetenkListApiResponse,
      PostExecFivetenkListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/fivetenk/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecDenovoList: build.query<
      PostExecDenovoListApiResponse,
      PostExecDenovoListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/de-novo/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecPmaList: build.query<
      PostExecPmaListApiResponse,
      PostExecPmaListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/pma/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecPmaSupplementList: build.query<
      PostExecPmaSupplementListApiResponse,
      PostExecPmaSupplementListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/pma-supplement/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecHdeList: build.query<
      PostExecHdeListApiResponse,
      PostExecHdeListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/hde/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecHdeSupplementList: build.query<
      PostExecHdeSupplementListApiResponse,
      PostExecHdeSupplementListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/hde-supplement/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecProductCodeList: build.query<
      PostExecProductCodeListApiResponse,
      PostExecProductCodeListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/product-code/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postExecClinicalTrialList: build.query<
      PostExecClinicalTrialListApiResponse,
      PostExecClinicalTrialListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/exec/regulatory/clinical-trial/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postFivetenkList: build.query<
      PostFivetenkListApiResponse,
      PostFivetenkListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/fivetenk/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postDenovoList: build.query<
      PostDenovoListApiResponse,
      PostDenovoListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/de-novo/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postPmaList: build.query<PostPmaListApiResponse, PostPmaListApiArg>({
      query: (queryArg) => ({
        url: `/device-data/v1/pma/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postPmaSupplementList: build.query<
      PostPmaSupplementListApiResponse,
      PostPmaSupplementListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/pma-supplement/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postHdeList: build.query<PostHdeListApiResponse, PostHdeListApiArg>({
      query: (queryArg) => ({
        url: `/device-data/v1/hde/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postHdeSupplementList: build.query<
      PostHdeSupplementListApiResponse,
      PostHdeSupplementListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/hde-supplement/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postPmiProductCodeList: build.query<
      PostPmiProductCodeListApiResponse,
      PostPmiProductCodeListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/pmi/product-code/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postPmiRecallInternationalAuList: build.query<
      PostPmiRecallInternationalAuListApiResponse,
      PostPmiRecallInternationalAuListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/pmi/recall/list/international/au`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postPmiRecallInternationalCaList: build.query<
      PostPmiRecallInternationalCaListApiResponse,
      PostPmiRecallInternationalCaListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/pmi/recall/list/international/ca`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getPmiApplicationTypeMeta: build.query<
      GetPmiApplicationTypeMetaApiResponse,
      GetPmiApplicationTypeMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/pmi/meta/application/type`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getGuidanceList: build.query<
      GetGuidanceListApiResponse,
      GetGuidanceListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/guidance/list`,
        params: { primaryIdHash: queryArg },
      }),
    }),
    postGuidanceList: build.query<
      PostGuidanceListApiResponse,
      PostGuidanceListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/guidance/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getFivetenkDetails: build.query<
      GetFivetenkDetailsApiResponse,
      GetFivetenkDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/device-data/v1/fivetenk/${queryArg}` }),
    }),
    getDenovoDetails: build.query<
      GetDenovoDetailsApiResponse,
      GetDenovoDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/device-data/v1/de-novo/${queryArg}` }),
    }),
    getPmaDetails: build.query<GetPmaDetailsApiResponse, GetPmaDetailsApiArg>({
      query: (queryArg) => ({ url: `/device-data/v1/pma/${queryArg}` }),
    }),
    getPmaSupplementDetails: build.query<
      GetPmaSupplementDetailsApiResponse,
      GetPmaSupplementDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/pma/${queryArg.pmaNumber}/supplement/${queryArg.pmaSupplementNumber}`,
      }),
    }),
    getHdeDetails: build.query<GetHdeDetailsApiResponse, GetHdeDetailsApiArg>({
      query: (queryArg) => ({ url: `/device-data/v1/hde/${queryArg}` }),
    }),
    getHdeSupplementDetails: build.query<
      GetHdeSupplementDetailsApiResponse,
      GetHdeSupplementDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/hde/${queryArg.hdeNumber}/supplement/${queryArg.hdeSupplementNumber}`,
      }),
    }),
    postClinicalTrialList: build.query<
      PostClinicalTrialListApiResponse,
      PostClinicalTrialListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/clinical-trial/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getClinicalTrialDetails: build.query<
      GetClinicalTrialDetailsApiResponse,
      GetClinicalTrialDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/clinical-trial/${queryArg}`,
      }),
    }),
    getApplicationMeta: build.query<
      GetApplicationMetaApiResponse,
      GetApplicationMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/meta/application`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getApplicationTypeMeta: build.query<
      GetApplicationTypeMetaApiResponse,
      GetApplicationTypeMetaApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/meta/application/type`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postRegulationList: build.query<
      PostRegulationListApiResponse,
      PostRegulationListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/regulation/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getRegulationDetails: build.query<
      GetRegulationDetailsApiResponse,
      GetRegulationDetailsApiArg
    >({
      query: (queryArg) => ({ url: `/device-data/v1/regulation/${queryArg}` }),
    }),
    getProductCodeDetails: build.query<
      GetProductCodeDetailsApiResponse,
      GetProductCodeDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/product-code/${queryArg}`,
      }),
    }),
    postProductCodeList: build.query<
      PostProductCodeListApiResponse,
      PostProductCodeListApiArg
    >({
      query: (queryArg) => ({
        url: `/device-data/v1/product-code/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getAllList: build.query<GetAllListApiResponse, GetAllListApiArg>({
      query: (queryArg) => ({
        url: `/device-data/v1/summary/list`,
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type PostBasicFivetenkListApiResponse =
  /** status 200 Successful Response */ BasicFiveTenK[];
export type PostBasicFivetenkListApiArg = FiveTenKQuery;
export type PostBasicDenovoListApiResponse =
  /** status 200 Successful Response */ BasicDeNovo[];
export type PostBasicDenovoListApiArg = DeNovoQuery;
export type PostBasicPmaListApiResponse =
  /** status 200 Successful Response */ BasicPma[];
export type PostBasicPmaListApiArg = PmaQuery;
export type PostBasicPmaSupplementListApiResponse =
  /** status 200 Successful Response */ BasicPmaSupplement[];
export type PostBasicPmaSupplementListApiArg = PmaSupplementQuery;
export type PostBasicHdeListApiResponse =
  /** status 200 Successful Response */ BasicHde[];
export type PostBasicHdeListApiArg = HdeQuery;
export type PostBasicHdeSupplementListApiResponse =
  /** status 200 Successful Response */ BasicHdeSupplement[];
export type PostBasicHdeSupplementListApiArg = HdeSupplementQuery;
export type GetBasicFivetenkDetailsApiResponse =
  /** status 200 Successful Response */ BasicFiveTenK;
export type GetBasicFivetenkDetailsApiArg = string;
export type GetBasicDenovoDetailsApiResponse =
  /** status 200 Successful Response */ BasicDeNovo;
export type GetBasicDenovoDetailsApiArg = string;
export type GetBasicPmaDetailsApiResponse =
  /** status 200 Successful Response */ BasicPma;
export type GetBasicPmaDetailsApiArg = string;
export type GetBasicPmaSupplementDetailsApiResponse =
  /** status 200 Successful Response */ BasicPmaSupplement;
export type GetBasicPmaSupplementDetailsApiArg = {
  pmaNumber: string;
  pmaSupplementNumber: number;
};
export type GetBasicHdeDetailsApiResponse =
  /** status 200 Successful Response */ BasicHde;
export type GetBasicHdeDetailsApiArg = string;
export type GetBasicHdeSupplementDetailsApiResponse =
  /** status 200 Successful Response */ BasicHdeSupplement;
export type GetBasicHdeSupplementDetailsApiArg = {
  hdeNumber: string;
  hdeSupplementNumber: number;
};
export type PostBasicClinicalTrialListApiResponse =
  /** status 200 Successful Response */ BasicClinicalTrial[];
export type PostBasicClinicalTrialListApiArg = ClinicalTrialQuery;
export type GetBasicClinicalTrialDetailsApiResponse =
  /** status 200 Successful Response */ BasicClinicalTrial;
export type GetBasicClinicalTrialDetailsApiArg = string;
export type GetBasicProductCodeDetailsApiResponse =
  /** status 200 Successful Response */ BasicProductCode;
export type GetBasicProductCodeDetailsApiArg = string;
export type PostBasicProductCodeListApiResponse =
  /** status 200 Successful Response */ BasicProductCode[];
export type PostBasicProductCodeListApiArg = ProductCodeQuery;
export type GetBasicEcosystemApiResponse =
  /** status 200 Successful Response */ EcosystemResponse;
export type GetBasicEcosystemApiArg = EcosystemQuery;
export type PostBasicGuidanceListApiResponse =
  /** status 200 Successful Response */ BasicGuidance[];
export type PostBasicGuidanceListApiArg = GuidanceQuery;
export type GetBasicApplicationTypeMetaApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string;
  };
export type GetBasicApplicationTypeMetaApiArg =
  BodyGetBasicApplicationTypeMetaDeviceDataV1BasicMetaApplicationTypePost;
export type GetBasicRecallEventListApiResponse =
  /** status 200 Successful Response */ RecallEventList;
export type GetBasicRecallEventListApiArg = RecallEventQuery;
export type PostBasicRegulationListApiResponse =
  /** status 200 Successful Response */ BasicRegulation[];
export type PostBasicRegulationListApiArg = RegulationQuery;
export type GetBasicRegulationDetailsApiResponse =
  /** status 200 Successful Response */ BasicRegulation;
export type GetBasicRegulationDetailsApiArg = string;
export type GetBasicAllListApiResponse =
  /** status 200 Successful Response */ SummaryResponse[];
export type GetBasicAllListApiArg = SummaryQuery;
export type GetDashboardApiResponse =
  /** status 200 Successful Response */ Dashboard;
export type GetDashboardApiArg = void;
export type GetEcosystemApiResponse =
  /** status 200 Successful Response */ EcosystemResponse;
export type GetEcosystemApiArg = EcosystemQuery;
export type PostExecFivetenkListApiResponse =
  /** status 200 Successful Response */ FiveTenK[];
export type PostExecFivetenkListApiArg = FiveTenKQuery;
export type PostExecDenovoListApiResponse =
  /** status 200 Successful Response */ DeNovo[];
export type PostExecDenovoListApiArg = DeNovoQuery;
export type PostExecPmaListApiResponse =
  /** status 200 Successful Response */ Pma[];
export type PostExecPmaListApiArg = PmaQuery;
export type PostExecPmaSupplementListApiResponse =
  /** status 200 Successful Response */ PmaSupplement[];
export type PostExecPmaSupplementListApiArg = PmaSupplementQuery;
export type PostExecHdeListApiResponse =
  /** status 200 Successful Response */ Hde[];
export type PostExecHdeListApiArg = HdeQuery;
export type PostExecHdeSupplementListApiResponse =
  /** status 200 Successful Response */ HdeSupplement[];
export type PostExecHdeSupplementListApiArg = HdeSupplementQuery;
export type PostExecProductCodeListApiResponse =
  /** status 200 Successful Response */ ProductCode[];
export type PostExecProductCodeListApiArg = ProductCodeQuery;
export type PostExecClinicalTrialListApiResponse =
  /** status 200 Successful Response */ ClinicalTrial[];
export type PostExecClinicalTrialListApiArg = ClinicalTrialQuery;
export type PostFivetenkListApiResponse =
  /** status 200 Successful Response */ FiveTenK[];
export type PostFivetenkListApiArg = FiveTenKQuery;
export type PostDenovoListApiResponse =
  /** status 200 Successful Response */ DeNovo[];
export type PostDenovoListApiArg = DeNovoQuery;
export type PostPmaListApiResponse =
  /** status 200 Successful Response */ Pma[];
export type PostPmaListApiArg = PmaQuery;
export type PostPmaSupplementListApiResponse =
  /** status 200 Successful Response */ PmaSupplement[];
export type PostPmaSupplementListApiArg = PmaSupplementQuery;
export type PostHdeListApiResponse =
  /** status 200 Successful Response */ Hde[];
export type PostHdeListApiArg = HdeQuery;
export type PostHdeSupplementListApiResponse =
  /** status 200 Successful Response */ HdeSupplement[];
export type PostHdeSupplementListApiArg = HdeSupplementQuery;
export type PostPmiProductCodeListApiResponse =
  /** status 200 Successful Response */ ProductCode[];
export type PostPmiProductCodeListApiArg = ProductCodeQuery;
export type PostPmiRecallInternationalAuListApiResponse =
  /** status 200 Successful Response */ AuRecall[];
export type PostPmiRecallInternationalAuListApiArg = RecallQuery;
export type PostPmiRecallInternationalCaListApiResponse =
  /** status 200 Successful Response */ CaRecall[];
export type PostPmiRecallInternationalCaListApiArg = RecallQuery;
export type GetPmiApplicationTypeMetaApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string;
  };
export type GetPmiApplicationTypeMetaApiArg =
  BodyGetPmiApplicationTypeMetaDeviceDataV1PmiMetaApplicationTypePost;
export type GetGuidanceListApiResponse =
  /** status 200 Successful Response */ Guidance[];
export type GetGuidanceListApiArg = string;
export type PostGuidanceListApiResponse =
  /** status 200 Successful Response */ Guidance[];
export type PostGuidanceListApiArg = GuidanceQuery;
export type GetFivetenkDetailsApiResponse =
  /** status 200 Successful Response */ FiveTenK;
export type GetFivetenkDetailsApiArg = string;
export type GetDenovoDetailsApiResponse =
  /** status 200 Successful Response */ DeNovo;
export type GetDenovoDetailsApiArg = string;
export type GetPmaDetailsApiResponse =
  /** status 200 Successful Response */ Pma;
export type GetPmaDetailsApiArg = string;
export type GetPmaSupplementDetailsApiResponse =
  /** status 200 Successful Response */ PmaSupplement;
export type GetPmaSupplementDetailsApiArg = {
  pmaNumber: string;
  pmaSupplementNumber: number;
};
export type GetHdeDetailsApiResponse =
  /** status 200 Successful Response */ Hde;
export type GetHdeDetailsApiArg = string;
export type GetHdeSupplementDetailsApiResponse =
  /** status 200 Successful Response */ HdeSupplement;
export type GetHdeSupplementDetailsApiArg = {
  hdeNumber: string;
  hdeSupplementNumber: number;
};
export type PostClinicalTrialListApiResponse =
  /** status 200 Successful Response */ ClinicalTrial[];
export type PostClinicalTrialListApiArg = ClinicalTrialQuery;
export type GetClinicalTrialDetailsApiResponse =
  /** status 200 Successful Response */ ClinicalTrial;
export type GetClinicalTrialDetailsApiArg = string;
export type GetApplicationMetaApiResponse =
  /** status 200 Successful Response */ ApplicationMeta;
export type GetApplicationMetaApiArg = Application;
export type GetApplicationTypeMetaApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string;
  };
export type GetApplicationTypeMetaApiArg =
  BodyGetApplicationTypeMetaDeviceDataV1MetaApplicationTypePost;
export type PostRegulationListApiResponse =
  /** status 200 Successful Response */ Regulation[];
export type PostRegulationListApiArg = RegulationQuery;
export type GetRegulationDetailsApiResponse =
  /** status 200 Successful Response */ Regulation;
export type GetRegulationDetailsApiArg = string;
export type GetProductCodeDetailsApiResponse =
  /** status 200 Successful Response */ ProductCode;
export type GetProductCodeDetailsApiArg = string;
export type PostProductCodeListApiResponse =
  /** status 200 Successful Response */ ProductCode[];
export type PostProductCodeListApiArg = ProductCodeQuery;
export type GetAllListApiResponse =
  /** status 200 Successful Response */ SummaryResponse[];
export type GetAllListApiArg = SummaryQuery;
export type Count = {
  count?: number | null;
};
export type Address = {
  street1: string;
  street3?: string | null;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
};
export type Person = {
  name: string;
  addressString?: string | null;
  address?: Address | null;
  contact?: string | null;
};
export type Classification = {
  deviceClass: string;
};
export type ClinicalTrialSourceEnum = "clinicaltrials.gov";
export type ClinicalTrialSummary = {
  trialId: string;
  trialSource?: ClinicalTrialSourceEnum | null;
  scrapedFdaUrl?: string | null;
};
export type DecisionCodeEnum =
  | "SESU"
  | "SN"
  | "SESP"
  | "PT"
  | "SESD"
  | "SESK"
  | "SEKD"
  | "SI"
  | "ST"
  | "SE"
  | "SESE";
export type Decision = {
  code: DecisionCodeEnum;
  text: string;
};
export type NameValueDict = {
  name: string;
  value: string;
};
export type LineageApplication = {
  applicationType: string;
  applicationNumber: string;
};
export type BasicLineage = {
  children?: (LineageApplication | number)[];
  parents?: LineageApplication[];
  siblings?: {
    [key: string]: (LineageApplication | number)[];
  } | null;
  vertexDegree: number;
  _id?: string;
};
export type AccreditedPerson = {
  name: string;
  address: Address;
  contact: string;
  phone: string;
  email?: string | null;
  website?: string | null;
  scrapedFdaUrl: string;
};
export type ProductCodeTypeEnum = "CLASSIFICATION" | "SUBSEQUENT";
export type PremarketReview = {
  name: string;
  scrapedFdaUrl?: string | null;
};
export type RecognizedConsensusStandards = {
  code: string;
  title: string;
  scrapedFdaUrl: string;
};
export type RelatedCountsDict = {
  adverseEvent?: number;
  deNovo?: number;
  fiveTenK?: number;
  hde?: number;
  pma?: number;
  product?: number;
  recall?: number;
};
export type ThirdPartyReview = {
  eligible: boolean;
  programName?: string | null;
  scrapedFdaUrl?: string | null;
};
export type Tplc = {
  scrapedFdaUrl: string;
};
export type BasicProductCode = {
  _id?: string;
  accreditedPersons?: AccreditedPerson[] | null;
  code: string;
  codeType?: ProductCodeTypeEnum | null;
  definition?: string | null;
  device?: string | null;
  deviceClass?: string | null;
  deviceClassification?: string | null;
  gmpExempt?: boolean | null;
  implantedDevice?: boolean | null;
  lifeSustainSupportDevice?: boolean | null;
  notClassifiedReason?: string | null;
  physicalState?: string | null;
  premarketReview?: PremarketReview[] | null;
  productCode?: string | null;
  products?: Count;
  recognizedConsensusStandards?: RecognizedConsensusStandards[] | null;
  regulationDescription?: string | null;
  regulationMedicalSpecialty?: string | null;
  regulationName?: string | null;
  regulationNumber?: string | null;
  relatedCounts?: RelatedCountsDict | null;
  reviewPanel?: string | null;
  submissionType?: string | null;
  summaryMalfunctionReportingEligible?: boolean | null;
  targetArea?: string | null;
  technicalMethod?: string | null;
  thirdPartyReview?: ThirdPartyReview | null;
  tplc?: Tplc | null;
  unclassifiedReason?: string | null;
};
export type RelatedDocCategoryEnum = "FDA_PDF";
export type RelatedDocContentTypeEnum = "application/pdf";
export type RelatedDoc = {
  category?: RelatedDocCategoryEnum | null;
  contentLength: number;
  contentType: RelatedDocContentTypeEnum;
  lastModified: string;
  createdDatetime?: string | null;
  name: string;
  fullUrl?: string | null;
};
export type ScrapedUrl = {
  name: string;
  scrapedUrl: string;
  title: string;
};
export type BasicFiveTenK = {
  _id?: string;
  adverseEvents?: Count | null;
  applicant?: Person | null;
  applicationType?: string | null;
  classification?: Classification | null;
  classificationAdvisoryCommittee?: string | null;
  classificationName?: string | null;
  clinicalTrials?: ClinicalTrialSummary[] | null;
  combinationProduct?: boolean | null;
  correspondent?: Person | null;
  currentHolder?: Person | null;
  dateReceived?: string | null;
  decision?: Decision | null;
  decisionDate?: string | null;
  deviceClassificationName?: string | null;
  deviceName?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  expeditedReview?: boolean | null;
  fullText?: NameValueDict[] | null;
  hasFoiaDocs?: boolean;
  indicationsForUse?: string | null;
  isCber?: boolean | null;
  kNumber?: string | null;
  lineage?: BasicLineage | null;
  products?: Count;
  productCodes?: BasicProductCode[] | null;
  recalls?: Count;
  regulationMedicalSpecialty?: string | null;
  regulationNumber?: string | null;
  relatedDocs?: RelatedDoc[] | null;
  reviewAdvisoryCommittee?: string | null;
  reviewMedicalSpeciality?: string | null;
  reviewPanel?: string | null;
  reviewTime?: number | null;
  summary?: ScrapedUrl | null;
  thirdPartyReviewed?: boolean | null;
  tradeNames?: string[] | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type FiveTenKQuery = {
  fields?: string[] | null;
  kNumbers?: string[] | null;
  productCodes?: string[] | null;
};
export type DecisionCodeEnum2 = "DENG" | "DENOVOGRANT";
export type Decision2 = {
  code: DecisionCodeEnum2;
  text: string;
};
export type DeNovoTypeEnum = "Direct" | "Post-NSE" | "510(k) Traditional";
export type BasicDeNovo = {
  _id?: string;
  adverseEvents?: Count | null;
  classification?: Classification | null;
  classificationAdvisoryCommittee?: string | null;
  contact?: string | null;
  dateReceived?: string | null;
  deNovoNumber?: string | null;
  decision?: Decision2 | null;
  decisionDate?: string | null;
  deviceClassificationName?: string | null;
  deviceName?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  expeditedReview?: boolean | null;
  fdaReview?: ScrapedUrl | null;
  hasFoiaDocs?: boolean | null;
  inVitroDevice?: boolean | null;
  isCber?: boolean | null;
  kNumber?: string | null;
  lineage?: BasicLineage | null;
  products?: Count;
  productCodes?: BasicProductCode[] | null;
  recalls?: Count | null;
  reclassificationOrder?: ScrapedUrl | null;
  regulationNumber?: string | null;
  relatedDocs?: RelatedDoc[] | null;
  requester?: Person | null;
  reviewAdvisoryCommittee?: string | null;
  reviewTime?: number | null;
  submissionType?: DeNovoTypeEnum | null;
  thirdPartyReviewed?: boolean | null;
};
export type DeNovoQuery = {
  fields?: string[] | null;
  deNovoNumbers?: string[] | null;
  productCodes?: string[] | null;
};
export type PostApprovalStudy = {
  title: string;
  text: string;
  scrapedUrl: string;
};
export type PmaSupplementData = {
  count?: number;
  productCodes: string[];
  relatedDocs: {
    [key: string]: RelatedDoc[];
  };
  relatedDocsCount?: number;
};
export type BasicPma = {
  _id?: string;
  adverseEvents?: Count | null;
  advisoryCommittee?: string | null;
  apcbDate?: string | null;
  applicant?: Person | null;
  approvalOrder?: ScrapedUrl | null;
  approvalOrderStatement?: string | null;
  classification?: Classification | null;
  clinicalTrials?: ClinicalTrialSummary[] | null;
  combinationProduct?: boolean | null;
  conversionDate?: string | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  device?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  genericName?: string | null;
  hasFoiaDocs?: boolean | null;
  isCber?: boolean | null;
  labeling?: ScrapedUrl | null;
  noticeDate?: string | null;
  pmaNumber?: string | null;
  postApprovalStudy?: PostApprovalStudy | null;
  products?: Count;
  productCodes?: BasicProductCode[] | null;
  recalls?: Count;
  reclassifiedDate?: string | null;
  relatedDocs?: RelatedDoc[] | null;
  reviewTime?: number | null;
  summary?: ScrapedUrl | null;
  supplementsData?: PmaSupplementData | null;
  thirdPartyReviewEligible?: boolean | null;
  withdrawalDate?: string | null;
};
export type PmaQuery = {
  fields?: string[] | null;
  pmaNumbers?: string[] | null;
  productCodes?: string[] | null;
};
export type BasicPmaSupplement = {
  _id?: string;
  advisoryCommittee?: string | null;
  applicant?: Person | null;
  approvalOrderStatement?: string | null;
  combinationProduct?: boolean | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  device?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  genericName?: string | null;
  isCber?: boolean | null;
  noticeDate?: string | null;
  pmaNumber?: string | null;
  productCodes?: BasicProductCode[] | null;
  regulationNumber?: string | null;
  reviewTime?: number | null;
  supplementNumber?: number | null;
  supplementReason?: string | null;
  supplementType?: string | null;
};
export type PmaSupplementQuery = {
  fields?: string[] | null;
  pmaNumbers: string[];
};
export type HdeSupplementData = {
  count?: number;
  productCodes: string[];
  relatedDocs: {
    [key: string]: RelatedDoc[];
  };
  relatedDocsCount?: number;
};
export type BasicHde = {
  _id?: string;
  adverseEvents?: Count | null;
  advisoryCommittee?: string | null;
  applicant?: Person | null;
  approvalOrderStatement?: string | null;
  classificationName?: string | null;
  clinicalTrials?: ClinicalTrialSummary[] | null;
  combinationProduct?: boolean | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  hasFoiaDocs?: boolean | null;
  hdeNumber?: string | null;
  isCber?: boolean | null;
  noticeDate?: string | null;
  products?: Count;
  productCodes?: BasicProductCode[] | null;
  postApprovalStudy?: PostApprovalStudy | null;
  recalls?: Count;
  relatedDocs?: RelatedDoc[] | null;
  reviewTime?: number | null;
  supplementsData?: HdeSupplementData | null;
  tradeName?: string | null;
};
export type HdeQuery = {
  fields?: string[] | null;
  hdeNumbers?: string[] | null;
  productCodes?: string[] | null;
};
export type BasicHdeSupplement = {
  _id?: string;
  advisoryCommittee?: string | null;
  applicant?: Person | null;
  approvalOrderStatement?: string | null;
  classificationName?: string | null;
  combinationProduct?: boolean | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  hdeNumber?: string | null;
  isCber?: boolean | null;
  noticeData?: string | null;
  productCodes?: string[] | null;
  reviewTime?: number | null;
  supplementNumber?: number | null;
  supplementReason?: string | null;
  supplementType?: string | null;
  tradeName?: string | null;
};
export type HdeSupplementQuery = {
  fields?: string[] | null;
  hdeNumbers: string[];
};
export type BasicClinicalTrial = {
  nctId: string;
  basil?: object | null;
  history?: object | null;
  study?: object | null;
  topics?: object | null;
};
export type ClinicalTrialQuery = {
  fields?: string[] | null;
  nctIds: string[];
};
export type ProductCodeQuery = {
  fields?: string[] | null;
  productCodes: string[];
};
export type Lineage = {
  children?: LineageApplication[];
  parents?: LineageApplication[];
  siblings?: {
    [key: string]: LineageApplication[];
  } | null;
  vertexDegree: number;
  _id?: string;
};
export type ProductCode = {
  _id?: string;
  accreditedPersons?: AccreditedPerson[] | null;
  code: string;
  codeType?: ProductCodeTypeEnum | null;
  definition?: string | null;
  device?: string | null;
  deviceClass?: string | null;
  deviceClassification?: string | null;
  gmpExempt?: boolean | null;
  implantedDevice?: boolean | null;
  lifeSustainSupportDevice?: boolean | null;
  notClassifiedReason?: string | null;
  physicalState?: string | null;
  premarketReview?: PremarketReview[] | null;
  productCode?: string | null;
  products?: Count;
  recognizedConsensusStandards?: RecognizedConsensusStandards[] | null;
  regulationDescription?: string | null;
  regulationMedicalSpecialty?: string | null;
  regulationName?: string | null;
  regulationNumber?: string | null;
  relatedCounts?: RelatedCountsDict | null;
  reviewPanel?: string | null;
  submissionType?: string | null;
  summaryMalfunctionReportingEligible?: boolean | null;
  targetArea?: string | null;
  technicalMethod?: string | null;
  thirdPartyReview?: ThirdPartyReview | null;
  tplc?: Tplc | null;
  unclassifiedReason?: string | null;
};
export type Ecosystem = {
  applicant?: Person | null;
  applicationNumber: string;
  applicationType: string;
  classification?: Classification | null;
  decisionDate?: string | null;
  deviceName?: string | null;
  lineage?: Lineage | null;
  productCodes?: ProductCode[] | null;
  recalls?: Count;
};
export type EcosystemResponse = {
  items: {
    [key: string]: Ecosystem;
  };
};
export type EcosystemQuery = {
  applicationNumbers: string[];
};
export type BasicGuidance = {
  _id?: string;
  associatedMedia?: string | null;
  associatedMediaUrl?: string | null;
  center?: string | null;
  changed: string;
  commentCloseDate?: string | null;
  communicationType?: string | null;
  docketNumber?: string | null;
  docketUrl?: string | null;
  finalGuidance?: string | null;
  issueDate?: string | null;
  issuingOffice?: string[] | null;
  openComment?: boolean | null;
  primaryId: string;
  primaryIdHash: string;
  productField?: string[] | null;
  relatedDocs?: RelatedDoc[] | null;
  termNodeTid?: string[] | null;
  title?: string | null;
  topics?: string | null;
  topicsProduct?: string[] | null;
  url?: string | null;
};
export type GuidanceQuery = {
  fields?: string[] | null;
  primaryIdHashes: string[];
};
export type BodyGetBasicApplicationTypeMetaDeviceDataV1BasicMetaApplicationTypePost =
  {
    applicationNumbers: string[];
  };
export type CauseCount = {
  cause: string;
  count: number;
};
export type Cause = {
  causeCounts: CauseCount[];
  totalCount: number;
};
export type RecallingFirm = {
  name?: string | null;
  addressString?: string | null;
};
export type Recall = {
  _id?: string;
  action?: string | null;
  codeInformation?: string | null;
  createDate?: string | null;
  dateInitiatedByFirm?: string | null;
  datePosted?: string | null;
  dateTerminated?: string | null;
  distribution?: string | null;
  eventId?: string | null;
  fdaDeterminedCauses?: string[] | null;
  fdaWebId?: string | null;
  forAdditionalInformationContact?: string | null;
  hdeNumbers?: string[] | null;
  isClassified?: boolean | null;
  kNumbers?: string[] | null;
  manufacturerReasonsForRecall?: string[] | null;
  mergedDate?: string | null;
  pmaNumbers?: string[] | null;
  product?: string | null;
  quantityInCommerce?: string | null;
  recallClasses?: number[] | null;
  recallNumber?: string | null;
  recallProductCodes?: string[] | null;
  recallStatus?: string | null;
  recallTitle?: string | null;
  recallingFirm?: RecallingFirm | null;
};
export type Status = {
  status?: string | null;
  count?: number | null;
};
export type Event = {
  applicationNumbers?: {
    [key: string]: any[];
  } | null;
  dateInitiatedByFirm?: string | null;
  dateTerminated?: string | null;
  eventId?: string | null;
  fdaDeterminedCauses?: string[] | null;
  manufacturerReasonsForRecall?: string[] | null;
  numberOfApplications?: number | null;
  recallClasses?: number[] | null;
  recallingFirms?: RecallingFirm[] | null;
  recalls?: Recall[] | null;
  statuses?: Status[] | null;
};
export type RecallEventList = {
  causes: Cause;
  events: Event[];
};
export type RecallEventQuery = {
  fields?: string[] | null;
  deNovoNumbers?: string[] | null;
  eventIds?: string[] | null;
  hdeNumbers?: string[] | null;
  kNumbers?: string[] | null;
  pmaNumbers?: string[] | null;
  productCodes?: string[] | null;
};
export type ProductCodeSummary = {
  productCode: string;
  device: string;
  deviceClass: string;
  reviewPanel: string;
  regulationMedicalSpecialty: string;
};
export type BasicRegulation = {
  _id: string;
  applicants510k?: string[] | null;
  applicantsPma?: string[] | null;
  count510k?: number | null;
  countPma?: number | null;
  countPmasNonSupplemental?: number | null;
  deviceClass?: string[] | string | null;
  deviceEventCount?: number | null;
  identification?: string[] | string | null;
  ids510k?: string[] | null;
  idsPma?: string[] | null;
  isProductCode?: string[] | null;
  link: string;
  name?: string | null;
  names510k?: string[] | null;
  namesPma?: string[] | null;
  namesProductCode?: string[] | null;
  numApplications?: number | null;
  part: string;
  productCodes?: ProductCodeSummary[] | null;
  recallCount?: number | null;
  regulationNumber: string;
  subpart: string;
  type?: string | null;
};
export type RegulationQuery = {
  fields?: string[] | null;
  regulationNumbers: string[];
};
export type SummaryResponse = {
  applicantName?: string | null;
  applicationNumber?: string | null;
  applicationType?: string | null;
  brandFamily?: string[] | null;
  companyFamily?: string[] | null;
  decisionDate?: string | string | null;
  devicesCount?: number | null;
  latestPublishDate?: string | string | null;
  title?: string | null;
};
export type OrderByFieldNameEnum =
  | "GUDID_COUNT"
  | "PUBLISH_DATE"
  | "DECISION_DATE";
export type OrderBy = {
  fieldName?: OrderByFieldNameEnum;
  ascending?: boolean;
};
export type SummaryQuery = {
  devices: {
    [key: string]: any[];
  };
  companyNames?: string[];
  filters?: object[];
  orderBy?: OrderBy;
};
export type Count2 = {
  count: number;
};
export type ApplicationCount = {
  den: Count2;
  fivetenk: Count2;
  hde: Count2;
  pma: Count2;
};
export type Committee = {
  committee: string;
  count: number;
};
export type Committees = {
  committees: Committee[];
};
export type CommitteeCount = {
  den?: Committees | null;
  fivetenk?: Committees | null;
  hde?: Committees | null;
  pma?: Committees | null;
};
export type Dashboard = {
  applicationCounts: {
    [key: string]: ApplicationCount;
  };
  committeeCounts: {
    [key: string]: CommitteeCount;
  };
};
export type FiveTenK = {
  _id?: string;
  adverseEvents?: Count | null;
  applicant?: Person | null;
  applicationType?: string | null;
  classification?: Classification | null;
  classificationAdvisoryCommittee?: string | null;
  classificationName?: string | null;
  clinicalTrials?: ClinicalTrialSummary[] | null;
  combinationProduct?: boolean | null;
  correspondent?: Person | null;
  currentHolder?: Person | null;
  dateReceived?: string | null;
  decision?: Decision | null;
  decisionDate?: string | null;
  deviceClassificationName?: string | null;
  deviceName?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  expeditedReview?: boolean | null;
  fullText?: NameValueDict[] | null;
  hasFoiaDocs?: boolean;
  indicationsForUse?: string | null;
  isCber?: boolean | null;
  kNumber?: string | null;
  lineage?: Lineage | null;
  products?: Count;
  productCodes?: ProductCode[] | null;
  recalls?: Count;
  regulationMedicalSpecialty?: string | null;
  regulationNumber?: string | null;
  relatedDocs?: RelatedDoc[] | null;
  reviewAdvisoryCommittee?: string | null;
  reviewMedicalSpeciality?: string | null;
  reviewPanel?: string | null;
  reviewTime?: number | null;
  summary?: ScrapedUrl | null;
  thirdPartyReviewed?: boolean | null;
  tradeNames?: string[] | null;
};
export type DeNovo = {
  _id?: string;
  adverseEvents?: Count | null;
  classification?: Classification | null;
  classificationAdvisoryCommittee?: string | null;
  contact?: string | null;
  dateReceived?: string | null;
  deNovoNumber?: string | null;
  decision?: Decision2 | null;
  decisionDate?: string | null;
  deviceClassificationName?: string | null;
  deviceName?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  expeditedReview?: boolean | null;
  fdaReview?: ScrapedUrl | null;
  hasFoiaDocs?: boolean | null;
  inVitroDevice?: boolean | null;
  isCber?: boolean | null;
  kNumber?: string | null;
  lineage?: Lineage | null;
  products?: Count;
  productCodes?: ProductCode[] | null;
  recalls?: Count | null;
  reclassificationOrder?: ScrapedUrl | null;
  regulationNumber?: string | null;
  relatedDocs?: RelatedDoc[] | null;
  requester?: Person | null;
  reviewAdvisoryCommittee?: string | null;
  reviewTime?: number | null;
  submissionType?: DeNovoTypeEnum | null;
  thirdPartyReviewed?: boolean | null;
};
export type Pma = {
  _id?: string;
  adverseEvents?: Count | null;
  advisoryCommittee?: string | null;
  apcbDate?: string | null;
  applicant?: Person | null;
  approvalOrder?: ScrapedUrl | null;
  approvalOrderStatement?: string | null;
  classification?: Classification | null;
  clinicalTrials?: ClinicalTrialSummary[] | null;
  combinationProduct?: boolean | null;
  conversionDate?: string | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  device?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  genericName?: string | null;
  hasFoiaDocs?: boolean | null;
  isCber?: boolean | null;
  labeling?: ScrapedUrl | null;
  noticeDate?: string | null;
  pmaNumber?: string | null;
  postApprovalStudy?: PostApprovalStudy | null;
  products?: Count;
  productCodes?: ProductCode[] | null;
  recalls?: Count;
  reclassifiedDate?: string | null;
  relatedDocs?: RelatedDoc[] | null;
  reviewTime?: number | null;
  summary?: ScrapedUrl | null;
  supplementsData?: PmaSupplementData | null;
  thirdPartyReviewEligible?: boolean | null;
  withdrawalDate?: string | null;
};
export type PmaSupplement = {
  _id?: string;
  advisoryCommittee?: string | null;
  applicant?: Person | null;
  approvalOrderStatement?: string | null;
  combinationProduct?: boolean | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  device?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  genericName?: string | null;
  isCber?: boolean | null;
  noticeDate?: string | null;
  pmaNumber?: string | null;
  productCodes?: ProductCode[] | null;
  regulationNumber?: string | null;
  reviewTime?: number | null;
  supplementNumber?: number | null;
  supplementReason?: string | null;
  supplementType?: string | null;
};
export type Hde = {
  _id?: string;
  adverseEvents?: Count | null;
  advisoryCommittee?: string | null;
  applicant?: Person | null;
  approvalOrderStatement?: string | null;
  classificationName?: string | null;
  clinicalTrials?: ClinicalTrialSummary[] | null;
  combinationProduct?: boolean | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  hasFoiaDocs?: boolean | null;
  hdeNumber?: string | null;
  isCber?: boolean | null;
  noticeDate?: string | null;
  products?: Count;
  productCodes?: ProductCode[] | null;
  postApprovalStudy?: PostApprovalStudy | null;
  recalls?: Count;
  relatedDocs?: RelatedDoc[] | null;
  reviewTime?: number | null;
  supplementsData?: HdeSupplementData | null;
  tradeName?: string | null;
};
export type HdeSupplement = {
  _id?: string;
  advisoryCommittee?: string | null;
  applicant?: Person | null;
  approvalOrderStatement?: string | null;
  classificationName?: string | null;
  combinationProduct?: boolean | null;
  dateReceived?: string | null;
  decisionDate?: string | null;
  docNumber?: string | null;
  docTitle?: string | null;
  docType?: string;
  docketNumber?: string | null;
  expeditedReviewGranted?: boolean | null;
  hdeNumber?: string | null;
  isCber?: boolean | null;
  noticeData?: string | null;
  productCodes?: string[] | null;
  reviewTime?: number | null;
  supplementNumber?: number | null;
  supplementReason?: string | null;
  supplementType?: string | null;
  tradeName?: string | null;
};
export type ClinicalTrial = {
  nctId: string;
  basil?: object | null;
  history?: object | null;
  study?: object | null;
  topics?: object | null;
};
export type ContactInformation = {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
};
export type AuRecall = {
  _id?: string;
  contactInformation?: ContactInformation | null;
  productName?: string | null;
  productNameDescription?: string | null;
  reasonIssue?: string | null;
  recallAction?: string | null;
  recallActionInstructions?: string | null;
  recallActionLevel?: string | null;
  recallClass?: string | null;
  recallDate?: string | null;
  recallingFirm?: string | null;
  recallNumber?: string | null;
  typeOfProduct?: string | null;
};
export type RecallQuery = {
  fields?: string[] | null;
  recallNumbers: string[];
};
export type CaRecallAffectedProduct = {
  productName?: string[] | string | null;
  lotOrSerialNumber?: string[] | string | null;
  modelOrCatalogNumber?: string[] | string | null;
};
export type CaRecallCompany = {
  name?: string | null;
  address?: string[] | null;
};
export type CaRecall = {
  _id?: string;
  action?: string | null;
  affectedProducts?: CaRecallAffectedProduct[] | null;
  audience?: string[] | null;
  categories?: string[] | null;
  issue?: string | null;
  issueTypes?: string[] | null;
  lastModifiedDate?: string | null;
  mergedDate?: string | null;
  originalPublishedDate?: string | null;
  pageLayoutVersion?: string | null;
  product?: string | null;
  publishedBy?: string | null;
  recallClass?: string | null;
  recallDate?: string | null;
  recallStartDates?: string[] | null;
  recallNumber?: string | null;
  recallingFirm?: CaRecallCompany | CaRecallCompany[] | null;
  subcategory?: string[] | string | null;
  title?: string | null;
  typeOfCommunication?: string | null;
};
export type BodyGetPmiApplicationTypeMetaDeviceDataV1PmiMetaApplicationTypePost =
  {
    applicationNumbers: string[];
  };
export type Guidance = {
  _id?: string;
  associatedMedia?: string | null;
  associatedMediaUrl?: string | null;
  center?: string | null;
  changed: string;
  commentCloseDate?: string | null;
  communicationType?: string | null;
  docketNumber?: string | null;
  docketUrl?: string | null;
  finalGuidance?: string | null;
  issueDate?: string | null;
  issuingOffice?: string[] | null;
  openComment?: boolean | null;
  primaryId: string;
  primaryIdHash: string;
  productField?: string[] | null;
  relatedDocs?: RelatedDoc[] | null;
  termNodeTid?: string[] | null;
  title?: string | null;
  topics?: string | null;
  topicsProduct?: string[] | null;
  url?: string | null;
};
export type ApplicationMeta = {
  title: string;
};
export type Application = {
  docType: string;
  docId: string;
  docModel?: string | null;
};
export type BodyGetApplicationTypeMetaDeviceDataV1MetaApplicationTypePost = {
  applicationNumbers: string[];
};
export type Regulation = {
  _id: string;
  applicants510k?: string[] | null;
  applicantsPma?: string[] | null;
  count510k?: number | null;
  countPma?: number | null;
  countPmasNonSupplemental?: number | null;
  deviceClass?: string[] | string | null;
  deviceEventCount?: number | null;
  identification?: string[] | string | null;
  ids510k?: string[] | null;
  idsPma?: string[] | null;
  isProductCode?: string[] | null;
  link: string;
  name?: string | null;
  names510k?: string[] | null;
  namesPma?: string[] | null;
  namesProductCode?: string[] | null;
  numApplications?: number | null;
  part: string;
  productCodes?: ProductCodeSummary[] | null;
  recallCount?: number | null;
  regulationNumber: string;
  subpart: string;
  type?: string | null;
};
export const {
  usePostBasicFivetenkListQuery,
  usePostBasicDenovoListQuery,
  usePostBasicPmaListQuery,
  usePostBasicPmaSupplementListQuery,
  usePostBasicHdeListQuery,
  usePostBasicHdeSupplementListQuery,
  useGetBasicFivetenkDetailsQuery,
  useGetBasicDenovoDetailsQuery,
  useGetBasicPmaDetailsQuery,
  useGetBasicPmaSupplementDetailsQuery,
  useGetBasicHdeDetailsQuery,
  useGetBasicHdeSupplementDetailsQuery,
  usePostBasicClinicalTrialListQuery,
  useGetBasicClinicalTrialDetailsQuery,
  useGetBasicProductCodeDetailsQuery,
  usePostBasicProductCodeListQuery,
  useGetBasicEcosystemQuery,
  usePostBasicGuidanceListQuery,
  useGetBasicApplicationTypeMetaQuery,
  useGetBasicRecallEventListQuery,
  usePostBasicRegulationListQuery,
  useGetBasicRegulationDetailsQuery,
  useGetBasicAllListQuery,
  useGetDashboardQuery,
  useGetEcosystemQuery,
  usePostExecFivetenkListQuery,
  usePostExecDenovoListQuery,
  usePostExecPmaListQuery,
  usePostExecPmaSupplementListQuery,
  usePostExecHdeListQuery,
  usePostExecHdeSupplementListQuery,
  usePostExecProductCodeListQuery,
  usePostExecClinicalTrialListQuery,
  usePostFivetenkListQuery,
  usePostDenovoListQuery,
  usePostPmaListQuery,
  usePostPmaSupplementListQuery,
  usePostHdeListQuery,
  usePostHdeSupplementListQuery,
  usePostPmiProductCodeListQuery,
  usePostPmiRecallInternationalAuListQuery,
  usePostPmiRecallInternationalCaListQuery,
  useGetPmiApplicationTypeMetaQuery,
  useGetGuidanceListQuery,
  usePostGuidanceListQuery,
  useGetFivetenkDetailsQuery,
  useGetDenovoDetailsQuery,
  useGetPmaDetailsQuery,
  useGetPmaSupplementDetailsQuery,
  useGetHdeDetailsQuery,
  useGetHdeSupplementDetailsQuery,
  usePostClinicalTrialListQuery,
  useGetClinicalTrialDetailsQuery,
  useGetApplicationMetaQuery,
  useGetApplicationTypeMetaQuery,
  usePostRegulationListQuery,
  useGetRegulationDetailsQuery,
  useGetProductCodeDetailsQuery,
  usePostProductCodeListQuery,
  useGetAllListQuery,
} = injectedRtkApi;
